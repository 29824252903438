import React from "react";
import { Switch } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import GuestRoute from "./routes/GuestRoute";
import UserRoute from "./routes/UserRoute";
import DashboardPage from "./pages/DashboardPage";
import CategoriesPage from "./pages/CategoriesPage";
import AddCategoryPage from "./pages/AddCategoryPage";
import CategoriesListPage from "./pages/CategoriesListPage";
import ShowCategoryPage from "./pages/ShowCategoryPage";
import EditCategoryPage from "./pages/EditCategoryPage";
import AddProductPage from "./pages/AddProductPage";
import ProductsListPage from "./pages/ProductsListPage";
import AddBrandPage from "./pages/AddBrandPage";
import BrandsListPage from "./pages/BrandsListPage";
import CategoriesTreeViewPage from "./pages/CategoriesTreeViewPage";
import ShowProductPage from "./pages/ShowProductPage";
import AddSliderPage from "./pages/AddSliderPage";
import SlidersListPage from "./pages/SlidersListPage";
import EditSliderPage from "./pages/EditSliderPage";
import PurposesListPage from "./pages/PurposesListPage";
import CitiesListPage from "./pages/CitiesListPage";
import OrdersPage from "./pages/OrdersPage";
import UsersPage from "./pages/UsersPage";
import AddHomePageSliderPage from "./pages/AddHomePageSliderPage";
import HomePageSlidersListPage from "./pages/HomePageSlidersListPage";
import EditHomePageSliderPage from "./pages/EditHomePageSliderPage";
import AddShopPageSliderPage from "./pages/AddShopPageSliderPage";
import ShopPageSlidersListPage from "./pages/ShopPageSlidersListPage";
import EditShopPageSliderPage from "./pages/EditShopPageSliderPage";
import EditOrderPage from "./pages/EditOrderPage";

const App = ({ location }) => (
  <Switch>
    <GuestRoute path="/login" location={location} exact component={LoginPage} />
    <UserRoute
      path="/"
      location={location}
      exact
      component={ProductsListPage}
    />
    <UserRoute path="/orders/list" location={location} exact component={OrdersPage} />
    <UserRoute
      path="/categories"
      location={location}
      exact
      component={CategoriesPage}
    />
    <UserRoute
      path="/categories/add"
      location={location}
      exact
      component={AddCategoryPage}
    />
    <UserRoute
      path="/categories/list"
      location={location}
      exact
      component={CategoriesListPage}
    />
    <UserRoute
      path="/categories/tree"
      location={location}
      exact
      component={CategoriesTreeViewPage}
    />
    <UserRoute
      path="/categories/show/:id"
      location={location}
      exact
      component={ShowCategoryPage}
    />
    <UserRoute
      path="/categories/edit/:id"
      location={location}
      exact
      component={EditCategoryPage}
    />
    <UserRoute
      path="/products/add"
      location={location}
      exact
      component={AddProductPage}
    />
    <UserRoute
      path="/products/show/:id"
      location={location}
      exact
      component={ShowProductPage}
    />
    <UserRoute
      path="/brands/list"
      location={location}
      exact
      component={BrandsListPage}
    />
    <UserRoute
      path="/purposes/list"
      location={location}
      exact
      component={PurposesListPage}
    />
    <UserRoute
      path="/cities/list"
      location={location}
      exact
      component={CitiesListPage}
    />
    <UserRoute
      path="/brands/add"
      location={location}
      exact
      component={AddBrandPage}
    />
    <UserRoute
      path="/sliders/add"
      location={location}
      exact
      component={AddSliderPage}
    />
    <UserRoute
      path="/sliders/list"
      location={location}
      exact
      component={SlidersListPage}
    />
    <UserRoute
      path="/sliders/edit/:id"
      location={location}
      exact
      component={EditSliderPage}
    />
    <UserRoute
      path="/orders"
      location={location}
      exact
      component={OrdersPage}
    />
   <UserRoute
      path="/orders/edit/:id"
      location={location}
      exact
      component={EditOrderPage}
    />
    <UserRoute path="/users" location={location} exact component={UsersPage} />
    <UserRoute
      path="/home-page-sliders/add"
      location={location}
      exact
      component={AddHomePageSliderPage}
    />
    <UserRoute
      path="/home-page-sliders/list"
      location={location}
      exact
      component={HomePageSlidersListPage}
    />
    <UserRoute
      path="/home-page-sliders/edit/:id"
      location={location}
      exact
      component={EditHomePageSliderPage}
    />
    <UserRoute
      path="/shop-page-sliders/add"
      location={location}
      exact
      component={AddShopPageSliderPage}
    />
    <UserRoute
      path="/shop-page-sliders/list"
      location={location}
      exact
      component={ShopPageSlidersListPage}
    />
    <UserRoute
      path="/shop-page-sliders/edit/:id"
      location={location}
      exact
      component={EditShopPageSliderPage}
    />
  </Switch>
);

export default App;
