import React from "react";
import api from "../api";
import { withRouter } from "react-router-dom";
import imgPath from "../utils/imgPath";
import price from "../utils/price";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

class OrdersContentComponent extends React.Component {
  state = {
    products: [],
    loaded: false,
    columns: [
      { title: "Номер заказа", field: "id" },
      {
        title: "Общая сумма",
        field: "amount",
        //render: rowData => price(rowData.amount)
      },
      {
        title: "Статус",
        field: "status",
        render: rowData => this.renderStatusTD(rowData.status)
      },
      {
        title: "Клиент",
        field: "user",
        render: rowData => (
          <Link
            style={{ textDecoration: "underline" }}
            to={`/users`}
          >
            {rowData.user.name}
          </Link>
        )
      },
      {
        title: "Товары",
        field: "products",
        render: rowData => this.renderProductsTD(rowData.products)
      },
      {
        title: "Создан",
        field: "created",
        render: rowData => this.renderCreatedAtTD(rowData.created_at)
      }
    ],
    data: []
  };

  renderProductsTD = products => {
    const dom = [];
    products.map((product, i) => {
      const label = (
        <Link
          style={{ textDecoration: "underline" }}
          to={`/products/show/${product.id}`}
        >
          <Typography variant="body1" gutterBottom>
            {i + 1}. {product.name} - <strong>{product.quantity} шт.</strong>
          </Typography>
        </Link>
      );
      dom.push(label);
    });
    return dom;
  };

  renderStatusTD = status => {
    let label = "";
    switch (status) {
      case "created":
        label = "Новый";
        break;
      case "failed":
        label = "Ошибка";
        break;
      case "payed":
        label = "Оплачено";
        break;
      case "delivered":
        label = "Доставлено";
        break;
      case "delivering":
        label = "В пути";
        break;
      case "return":
        label = "Возврат";
        break;

      default:
        label = "Неизвестно";
        break;
    }
    return label;
  };

  renderCreatedAtTD = date => {
    const event = new Date(date);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return event.toLocaleDateString("ru-RU", options);
  };

  componentDidMount() {
    api.orders
      .getAll()
      .then(products => this.setState({ data: products }))
      .finally(() => this.setState({ loaded: true }));
  }

  render() {
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header card-header-icon card-header-rose">
                  <div className="card-icon">
                    <i className="material-icons">assignment</i>
                  </div>
                  <h4 className="card-title ">Продажи</h4>
                </div>
                <div className="card-body">
                  <MaterialTable
                    options={{
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 30]
                    }}
                    isLoading={!this.state.loaded}
                    localization={{
                      pagination: {
                        labelDisplayedRows: "{from}-{to} из {count}",
                        labelRowsSelect: "строк",
                        firstAriaLabel: "Первая страница",
                        firstTooltip: "Первая страница",
                        previousAriaLabel: "Пред. страница",
                        previousTooltip: "Пред. страница",
                        nextAriaLabel: "След. страница",
                        nextTooltip: "След. страница",
                        lastAriaLabel: "Последняя страница",
                        lastTooltip: "Последняя страница"
                      },
                      toolbar: {
                        nRowsSelected: "{0} строк выбрано",
                        searchPlaceholder: "Поиск",
                        searchTooltip: "Поиск"
                      },
                      header: {
                        actions: "Действия"
                      },
                      body: {
                        emptyDataSourceMessage: "Нет данных",
                        filterRow: {
                          filterTooltip: "Фильтр"
                        },
                        editRow: {
                          deleteText: "Вы точно хотите удалить?",
                          cancelTooltip: "Отмена",
                          saveTooltip: "Сохранить"
                        },
                        addTooltip: "Добавить",
                        deleteTooltip: "Удалить",
                        editTooltip: "Редактировать"
                      }
                    }}
                    title="Список заказов"
                    columns={this.state.columns}
                    data={this.state.data}
                    onRowClick={(e, data) => {
                      this.props.history.push("/orders/edit/" + data.id);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OrdersContentComponent);
