import React from "react";
import { connect } from "react-redux";
import { userLoggedIn } from "../actions/auth";
import Validator from "validator";
import api from "../api";
import setAuthorizationHeader from "../utils/setAuthorizationHeader";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "@material-ui/core";

class LoginComponent extends React.Component {
  state = {
    credentials: {
      email: "",
      password: ""
    },
    loading: false,
    error: false,
    submitted: false,
    completed: 10,
    buffer: 10
  };

  onSubmit = e => {
    e.preventDefault();
    const error = this.validate(this.state.credentials);
    this.setState({ error, submitted: true });
    if (!error) {
      this.setState({ loading: true, completed: 10 });
      api.users
        .login(this.state.credentials)
        .then(auth => {
          this.setState({ completed: 50 });
          if (auth.user.admin) {
            this.props.dispatch(userLoggedIn(auth));
            localStorage.auth = JSON.stringify(auth);
            setAuthorizationHeader(auth.token);
          }

          if (!auth.user.admin) {
            this.setState({
              error: "У вас нет прав на просмотр данной страницы"
            });
          }
        })
        .catch(err =>
          this.setState({
            error: "Аккаунт с таким email и пароль не существует"
          })
        )
        .finally(() => this.setState({ completed: 100, loading: false }));
    }
  };

  onChange = e => {
    this.setState({
      submitted: false,
      credentials: {
        ...this.state.credentials,
        [e.target.name]: e.target.value
      }
    });
  };

  validate = credentials => {
    let error = false;
    if (!credentials.password) error = "Введите пароль";
    if (!Validator.isEmail(credentials.email)) error = "Неправильный email";
    return error;
  };

  render() {
    return (
      <div>
        <div className="card card-login">
          <div className="card-header card-header-rose text-center">
            <h4 className="card-title">Вход в админку</h4>
          </div>
          <div className="card-body ">
            <span className="bmd-form-group">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="material-icons">email</i>
                  </span>
                </div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={this.state.credentials.email}
                  onChange={e => this.onChange(e)}
                  className="form-control"
                />
              </div>
            </span>
            <span className="bmd-form-group">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="material-icons">lock_outline</i>
                  </span>
                </div>
                <input
                  type="password"
                  name="password"
                  placeholder="Пароль"
                  value={this.state.credentials.password}
                  onChange={e => this.onChange(e)}
                  className="form-control"
                />
              </div>
            </span>
          </div>
          <div className="card-footer justify-content-center">
            {this.state.loading && (
              <div style={{ width: "100%", marginTop: 20, marginBottom: 20 }}>
                <LinearProgress variant="query" />
              </div>
            )}
            {!this.state.loading && (
              <button
                style={{zIndex: 9999}}
                onClick={e => this.onSubmit(e)}
                className="btn btn-rose btn-link btn-lg"
              >
                Войти
              </button>
            )}
          </div>
        </div>
        {this.state.submitted && this.state.error && (
          <div className="alert alert-danger" role="alert">
            {this.state.error}
          </div>
        )}
      </div>
    );
  }
}

export default connect(null)(LoginComponent);
