import Axios from "axios";
import { API_URL } from "./const";

export default {
  categories: {
    getAll: () => Axios.get(API_URL + "/v1/categories").then(res => res.data),
    getAllWithChilds: () =>
      Axios.get(API_URL + "/v1/categories-with-childs").then(res => res.data),
    store: form =>
      Axios.post(API_URL + "/v1/categories", form).then(res => res.data),
    update: (id, form) =>
      Axios.put(API_URL + "/v1/categories/" + id, form).then(res => res.data),
    get: id =>
      Axios.get(API_URL + "/v1/categories/" + id).then(res => res.data),
    delete: id =>
      Axios.delete(API_URL + "/v1/categories/" + id).then(res => res.data)
  },
  products: {
    get: id => Axios.get(API_URL + "/v1/products/" + id).then(res => res.data),
    delete: id =>
      Axios.delete(API_URL + "/v1/products/" + id).then(res => res.data),
    getAll: () => Axios.get(API_URL + "/v1/products").then(res => res.data),
    store: form =>
      Axios.post(API_URL + "/v1/products", form, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(res => res.data),
    update: (id, form) =>
      Axios.put(API_URL + "/v1/products/" + id, form).then(res => res.data)
  },
  users: {
    login: credentials =>
      Axios.post(API_URL + "/v1/users/login", JSON.stringify(credentials)).then(
        res => res.data
      ),
    refreshToken: token =>
      Axios.put(API_URL + "/v1/users/token", {
        access_token: token.access_token,
        expires_in: token.expires_in,
        refresh_token: token.refresh_token
      }).then(res => res.data),
    getAll: () => Axios.get(API_URL + "/v1/users").then(res => res.data)
  },
  brands: {
    getAll: () => Axios.get(API_URL + "/v1/brands").then(res => res.data),
    store: form =>
      Axios.post(API_URL + "/v1/brands", form).then(res => res.data),
    update: (id, form) =>
      Axios.put(API_URL + "/v1/brands/" + id, form).then(res => res.data),
    delete: id =>
      Axios.delete(API_URL + "/v1/brands/" + id).then(res => res.data)
  },
  sliders: {
    getAll: () => Axios.get(API_URL + "/v1/sliders").then(res => res.data),
    store: form =>
      Axios.post(API_URL + "/v1/sliders", form).then(res => res.data),
    get: id => Axios.get(API_URL + "/v1/sliders/" + id).then(res => res.data),
    update: (id, form) =>
      Axios.put(API_URL + "/v1/sliders/" + id, form).then(res => res.data),
    delete: id =>
      Axios.delete(API_URL + "/v1/sliders/" + id).then(res => res.data)
  },
  homePageSliders: {
    getAll: () => Axios.get(API_URL + "/v1/home-page-sliders").then(res => res.data),
    store: form =>
      Axios.post(API_URL + "/v1/home-page-sliders", form).then(res => res.data),
    get: id => Axios.get(API_URL + "/v1/home-page-sliders/" + id).then(res => res.data),
    update: (id, form) =>
      Axios.put(API_URL + "/v1/home-page-sliders/" + id, form).then(res => res.data),
    delete: id =>
      Axios.delete(API_URL + "/v1/home-page-sliders/" + id).then(res => res.data)
  },
  shopPageSliders: {
    getAll: () => Axios.get(API_URL + "/v1/shop-page-sliders").then(res => res.data),
    store: form =>
      Axios.post(API_URL + "/v1/shop-page-sliders", form).then(res => res.data),
    get: id => Axios.get(API_URL + "/v1/shop-page-sliders/" + id).then(res => res.data),
    update: (id, form) =>
      Axios.put(API_URL + "/v1/shop-page-sliders/" + id, form).then(res => res.data),
    delete: id =>
      Axios.delete(API_URL + "/v1/shop-page-sliders/" + id).then(res => res.data)
  },
  purposes: {
    getAll: () => Axios.get(API_URL + "/v1/purposes").then(res => res.data),
    store: form =>
      Axios.post(API_URL + "/v1/purposes", form).then(res => res.data),
    update: (id, form) =>
      Axios.put(API_URL + "/v1/purposes/" + id, form).then(res => res.data),
    delete: id =>
      Axios.delete(API_URL + "/v1/purposes/" + id).then(res => res.data)
  },
  cities: {
    getAll: () => Axios.get(API_URL + "/v1/cities").then(res => res.data),
    store: form =>
      Axios.post(API_URL + "/v1/cities", form).then(res => res.data),
    update: (id, form) =>
      Axios.put(API_URL + "/v1/cities/" + id, form).then(res => res.data),
    delete: id =>
      Axios.delete(API_URL + "/v1/cities/" + id).then(res => res.data)
  },
  orders: {
    getAll: () => Axios.get(API_URL + "/v1/orders").then(res => res.data),
    get: id => Axios.get(API_URL + "/v1/orders/" + id).then(res => res.data),
    updateStatus: (id,status) => Axios.get(API_URL + "/v1/update-order?id=" + id + "&status=" + status).then(res => res.data),
  }
};
