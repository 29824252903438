import React from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import HomePageSlidersListContentComponent from './HomePageSlidersListContentComponent';

class HomePageSlidersListComponent extends React.Component {
    render() {
        return(
            <div className="main-panel">
                <HomePageSlidersListContentComponent location={this.props.location}/>
                <FooterComponent/>
            </div>
        );
    }
}

export default HomePageSlidersListComponent;