import React from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import AddBrandContentComponent from './AddBrandContentComponent';

class AddBrandComponent extends React.Component {
    render() {
        return (
            <div className="main-panel">
                <HeaderComponent title="Добавить брэнд" />
                <AddBrandContentComponent />
                <FooterComponent />
            </div>
        );
    }
}

export default AddBrandComponent;