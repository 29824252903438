
import { MENU_COLLAPSED, SIDEBAR_TOGGLED } from "../types";

export default function user(state = {}, action = {}) {
    switch (action.type) {
        case MENU_COLLAPSED:
            state.collapsedMenu = action.collapsedMenu;
            return { ...state };
        case SIDEBAR_TOGGLED:
            state.sidebarShown = action.shown;
            return { ...state };
        default:
            return state;
    }
}