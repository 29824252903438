import React from 'react';
import SidebarComponent from '../components/SidebarComponent';
import EditOrderComponent from '../components/EditOrderComponent';
import HeaderComponent from '../components/HeaderComponent';

class EditOrderPage extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <SidebarComponent location={this.props.location}/>
                <HeaderComponent/>
                <EditOrderComponent id={this.props.match.params.id}/>
            </div>
        );
    }
}

export default EditOrderPage;