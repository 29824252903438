import React from "react";
import api from "../api";
import { withRouter } from "react-router-dom";
import imgPath from "../utils/imgPath";
import price from "../utils/price";
import MaterialTable from "material-table";

class ProductsListContentComponent extends React.Component {
  state = {
    products: [],
    loaded: false,
    columns: [
      { title: "Фото", field: "images", render: rowData =>  <img src={imgPath(rowData.images[0].thumbnail)} style={{width: 50}}/> },
      { title: "ID", field: "id" },
      { title: "Наименование", field: "name" },
      { title: "Категория", field: "category",  render: rowData => rowData.category.name},
      { title: "Бренд", field: "brand",  render: rowData => rowData.brand.name},
      { title: "Цена", field: "price",  render: rowData => price(rowData.price)},
      { title: "В запасе", field: "count"},
    ],
    data: []
  };

  componentDidMount() {
    api.products.getAll().then(products => this.setState({ data: products })).finally(() => this.setState({loaded: true}));
  }

  render() {
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header card-header-icon card-header-rose">
                    <div className="card-icon">
                      <i className="material-icons">assignment</i>
                    </div>
                    <h4 className="card-title ">Товары</h4>
                  </div>
                  <div className="card-body">
                    <MaterialTable
                      options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30]
                      }}
                      editable={{
                        onRowDelete: oldData =>
                          api.products
                            .delete(oldData.id)
                            .then(res => {
                              this.setState(prevState => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                              });
                            })
                            .catch(err =>
                              this.setState({
                                openDialog: true,
                                error: "Неудалось удалить товар"
                              })
                            )
                      }}
                      isLoading={!this.state.loaded}
                      localization={{
                        pagination: {
                          labelDisplayedRows: "{from}-{to} из {count}",
                          labelRowsSelect: "строк",
                          firstAriaLabel: "Первая страница",
                          firstTooltip: "Первая страница",
                          previousAriaLabel: "Пред. страница",
                          previousTooltip: "Пред. страница",
                          nextAriaLabel: "След. страница",
                          nextTooltip: "След. страница",
                          lastAriaLabel: "Последняя страница",
                          lastTooltip: "Последняя страница"
                        },
                        toolbar: {
                          nRowsSelected: "{0} строк выбрано",
                          searchPlaceholder: "Поиск",
                          searchTooltip: "Поиск"
                        },
                        header: {
                          actions: "Действия"
                        },
                        body: {
                          emptyDataSourceMessage: "Нет данных",
                          filterRow: {
                            filterTooltip: "Фильтр"
                          },
                          editRow: {
                            deleteText: "Вы точно хотите удалить?",
                            cancelTooltip: "Отмена",
                            saveTooltip: "Сохранить"
                          },
                          addTooltip: "Добавить",
                          deleteTooltip: "Удалить",
                          editTooltip: "Редактировать"
                        }
                      }}
                      title="Список товаров"
                      columns={this.state.columns}
                      data={this.state.data}
                      onRowClick={(e, data) => {
                        this.props.history.push("/products/show/" + data.id);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ProductsListContentComponent);
