import React from 'react';
import SidebarComponent from '../components/SidebarComponent';
import AddProductComponent from '../components/AddProductComponent';
import HeaderComponent from '../components/HeaderComponent';

class AddProductPage extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <SidebarComponent location={this.props.location}/>
                <HeaderComponent/>
                <AddProductComponent />
            </div>
        );
    }
}

export default AddProductPage;