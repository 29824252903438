import { USER_LOGGED_IN, USER_LOGGED_OUT } from "../types";
import api from "../api";
import setAuthorizationHeader from "../utils/setAuthorizationHeader";

export const userLoggedIn = auth => ({
    type: USER_LOGGED_IN,
    auth
});

export const userLoggedOut = () => ({
    type: USER_LOGGED_OUT
});

export const login = credentials => dispatch =>
    api.users.login(credentials).then(auth => {
        localStorage.auth = JSON.stringify(auth);
        setAuthorizationHeader(auth.token);
        dispatch(userLoggedIn(auth));
    });

export const logout = () => dispatch => {
    localStorage.removeItem('auth');
    setAuthorizationHeader();
    dispatch(userLoggedOut());
}

export const refreshToken = token => dispatch =>
    api.users.refreshToken(token)
        .then(auth => {
            localStorage.auth = JSON.stringify(auth);
            setAuthorizationHeader(auth.token);
            dispatch(userLoggedIn(auth));
        })
        .catch(() => dispatch(userLoggedOut()))