import React from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import CategoriesListContentComponent from './CategoriesListContentComponent';

class CategoriesListComponent extends React.Component {
    render() {
        return(
            <div className="main-panel">  
                <CategoriesListContentComponent location={this.props.location}/>
                <FooterComponent/>
            </div>
        );
    }
}

export default CategoriesListComponent;