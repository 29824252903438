import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import api from "../api";
import ImageGallery from "react-image-gallery";
import imgPath from "../utils/imgPath";
import "react-image-gallery/styles/css/image-gallery.css";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { COLORS } from "../const";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

class ShowProductContentComponent extends React.Component {
  state = {
    categories: [],
    brands: [],
    purposes: [],
    openDialog: false,
    selectedCategory: null,
    selectedSubCategory: null,
    selectedSubSubCategory: null,
    selectedBrand: null,
    selectedPurpose: null,
    images: [],
    colors: [],
    name: "",
    description: "",
    price: "",
    count: "",
    submitted: false,
    error: false,
    loading: true,
    created: false,
    category: null
  };

  componentDidMount() {
    api.products.get(this.props.id).then(product => {
      this.setState({
        name: product.name,
        description: product.description,
        price: product.price,
        count: product.count,
        category: product.category,
        selectedCategory: product.category,
        loading: false
      });

      if (product.brand) {
        this.setState({ selectedBrand: product.brand.id });
      }

      if (product.purpose) {
        this.setState({ selectedPurpose: product.purpose.id });
      }

      if (product.colors) {
        const cols = [];
        product.colors.map(color => {
          cols.push(color.name);
        });
        this.setState({ colors: cols });
      }

      if (product.images) {
        const remoteImages = [];
        product.images.map(image => {
          const im = {
            source: imgPath(image.path),
            options: {
              type: "local"
            }
          };
          remoteImages.push(im);
        });
        this.setState({ images: remoteImages });
      }

      api.categories.getAllWithChilds().then(categories => {
        categories.map(category => {
          if (category.childs) {
            category.childs.map(child => {
              if (child.childs) {
                const ch = child.childs.filter(
                  subChild => subChild.id == product.category.id
                )[0];
                if (ch) {
                  this.setState({
                    selectedSubSubCategory: product.category.id,
                    selectedSubCategory: child,
                    selectedCategory: category
                  });
                }
              }
            });
          }
        });
        this.setState({ categories });
      });
    });
    api.purposes.getAll().then(purposes => this.setState({ purposes }));
    api.brands.getAll().then(brands => this.setState({ brands }));
  }

  onUpdate = e => {
    e.preventDefault();
    const error = this.validate();
    this.setState({ error, submitted: true, loading: true });
    if (error) this.setState({ loading: false, openDialog: true });
    if (!error) {
      const formData = new FormData();
      const images = this.state.images;
      images.map(image => {
        const file = new File([image], image.name);
        formData.append("images", file)
      });
      formData.append("name", this.state.name);
      formData.append("count", this.state.count);
      formData.append("description", this.state.description);
      formData.append(
        "price",
        isNaN(this.state.price)
          ? this.state.price.replace(/\s/g, "")
          : this.state.price
      );
      formData.append("category_id", this.state.selectedSubSubCategory);
      formData.append("brand_id", this.state.selectedBrand);

      if (this.state.selectedPurpose) {
        formData.append("purpose_id", this.state.selectedPurpose);
      }

      const colors = this.state.colors;
      if (colors.length > 0) {
        const productColors = [];
        colors.map(color => {
          const productColor = {
            name: color
          };
          productColors.push(productColor);
        });
        formData.append("colors", JSON.stringify(productColors));
      }

      api.products
        .update(this.props.id, formData)
        .then(res => {
          this.setState({
            created: true,
            loading: false,
            submitted: false,
            error: null
          });
        })
        .catch(err => {
          this.setState({
            error: "Произошла непредвиденная ошибка",
            openDialog: true
          });
          if (err.response && err.response.data && err.response.data.error) {
            this.setState({ error: err.response.data.error, openDialog: true });
          }
        })
        .finally(() => this.setState({ loading: false }));
    }
  };

  validate = () => {
    let error = false;
    if (this.state.name == "")
      error = "Наименование товара - обязательное поле.";

      if (this.state.count == "")
      error = "Количество товара - обязательное поле.";
    if (this.state.price == "") error = "Цена товара - обязательное поле.";
    if (this.state.description == "")
      error = "Описание товара - обязательное поле.";
    if (this.state.images.length == 0)
      error = "Прикрепите картинку для товара.";
    if (!this.state.selectedSubSubCategory)
      error = "Выберите подподкатегорию для товара.";
    if (!this.state.selectedBrand) error = "Выберите бренд для товара.";
    return error;
  };

  onNameChange = e => {
    this.setState({
      submitted: false,
      name: e.target.value
    });
  };

  onDescriptionChange = e => {
    this.setState({
      submitted: false,
      description: e.target.value
    });
  };

  onPriceChange = e => {
    this.setState({
      submitted: false,
      price: e.target.value
    });
  };

  onCountChange = e => {
    this.setState({
      submitted: false,
      count: e.target.value
    });
  };

  onCategoryChange = e => {
    const category = this.state.categories.filter(
      category => category.id == e.target.value
    )[0];
    this.setState({
      selectedCategory: category,
      submitted: false,
      selectedSubCategory: null,
      selectedSubSubCategory: null
    });
  };

  onSubCategoryChange = e => {
    const category = this.state.selectedCategory.childs.filter(
      category => category.id == e.target.value
    );
    if (category.length > 0) {
      this.setState({ selectedSubCategory: category[0], submitted: false });
    }
  };

  onSubSubCategoryChange = e => {
    this.setState({ selectedSubSubCategory: e.target.value, submitted: false });
  };

  onBrandChange = e => {
    this.setState({ selectedBrand: e.target.value, submitted: false });
  };

  onPurposeChange = e => {
    this.setState({ selectedPurpose: e.target.value, submitted: false });
  };

  render() {
    const cols = [];
    this.state.colors.map(color => {
      const obj = {
        name: color.name
      };
      cols.push(obj);
    });
    return (
      <div className="content">
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={this.state.created}
          autoHideDuration={6000}
          onClose={() => this.setState({ created: false })}
          message="Товар успешно сохранен"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => this.setState({ created: false })}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />

        <Dialog
          open={this.state.openDialog}
          onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Произашла ошибка"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ openDialog: false })}
              color="primary"
              autoFocus
            >
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>
        <div className="container-fluid">
          <div className="row">
            {this.state.loading ? (
              "Идет загрузка"
            ) : (
              <div className="col-md-12">
                <div className="card ">
                  <div className="card-header card-header-rose card-header-text">
                    <div className="card-text">
                      <h4 className="card-title">Данные товара</h4>
                    </div>
                  </div>

                  <div className="card-body ">
                    <div className="row">
                      <label className="col-sm-2 col-form-label">
                        Наименование товара
                      </label>
                      <div className="col-sm-10">
                        <div className="form-group">
                          <input
                            defaultValue={this.state.name}
                            onChange={e => this.onNameChange(e)}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-sm-2 col-form-label">
                        Описание товара
                      </label>
                      <div className="col-sm-10">
                        <div className="form-group">
                          <textarea
                            rows={8}
                            defaultValue={this.state.description}
                            onChange={e => this.onDescriptionChange(e)}
                            type="text"
                            className="form-control"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-sm-2 col-form-label">
                        Цена товара
                      </label>
                      <div className="col-sm-10">
                        <div className="form-group">
                          <input
                            defaultValue={this.state.price}
                            onChange={e => this.onPriceChange(e)}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-sm-2 col-form-label">
                        Количество товара
                      </label>
                      <div className="col-sm-10">
                        <div className="form-group">
                          <input
                            defaultValue={this.state.count}
                            onChange={e => this.onCountChange(e)}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-sm-2 col-form-label">
                        Категория товара
                      </label>
                      <div className="col-sm-10">
                        <div className="form-group">
                          <select
                            value={
                              this.state.selectedCategory
                                ? this.state.selectedCategory.id
                                : null
                            }
                            onChange={this.onCategoryChange}
                            className="form-control"
                          >
                            {this.state.categories.map(category => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {this.state.selectedCategory &&
                      this.state.selectedCategory.childs && (
                        <div className="row">
                          <label className="col-sm-2 col-form-label">
                            Подкатегория
                          </label>
                          <div className="col-sm-10">
                            <div className="form-group">
                              <select
                                value={
                                  this.state.selectedSubCategory
                                    ? this.state.selectedSubCategory.id
                                    : null
                                }
                                onChange={this.onSubCategoryChange}
                                className="form-control"
                              >
                                {this.state.selectedCategory.childs.map(
                                  category => (
                                    <option
                                      key={category.id}
                                      value={category.id}
                                    >
                                      {category.name}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}

                    {this.state.selectedSubCategory &&
                      this.state.selectedSubCategory.childs && (
                        <div className="row">
                          <label className="col-sm-2 col-form-label">
                            Еще подкатегория
                          </label>
                          <div className="col-sm-10">
                            <div className="form-group">
                              <select
                                value={
                                  this.state.selectedSubSubCategory
                                    ? this.state.selectedSubSubCategory.id
                                    : undefined
                                }
                                onChange={this.onSubSubCategoryChange}
                                className="form-control"
                              >
                                {this.state.selectedSubCategory.childs.map(
                                  category => (
                                    <option
                                      key={category.id}
                                      value={category.id}
                                    >
                                      {category.name}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                    <div className="row">
                      <label className="col-sm-2 col-form-label">
                        Бренд товара
                      </label>
                      <div className="col-sm-10">
                        <div className="form-group">
                          <select
                            onChange={this.onBrandChange}
                            className="form-control"
                          >
                            <option value={null}>Выберите бренд</option>
                            {this.state.brands.map(brand => (
                              <option key={brand.id} value={brand.id}>
                                {brand.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-sm-2 col-form-label">
                        Цель (для кого/чего)
                      </label>
                      <div className="col-sm-10">
                        <div className="form-group">
                          <select
                            onChange={this.onPurposeChange}
                            className="form-control"
                          >
                            <option value={null}>необязательно</option>
                            {this.state.purposes.map(purpose => (
                              <option key={purpose.id} value={purpose.id}>
                                {purpose.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-sm-2 col-form-label">
                        Цветы товара
                      </label>
                      <div className="col-sm-10">
                        <div className="form-group">
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            freeSolo
                            options={COLORS}
                            value={this.state.colors}
                            onChange={(e, v) => {
                              this.setState({ colors: v });
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Можно выбрать несколько вариантов"
                                placeholder="необязательно"
                                fullWidth
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-sm-2 col-form-label">
                        Изменить фотографии товара
                      </label>
                      <div className="col-sm-10">
                        <FilePond
                          acceptedFileTypes={[
                            "image/png",
                            "image/jpeg",
                            "image/jpg",
                            "image/png"
                          ]}
                          files={this.state.images}
                          ref={image => (this.pond = image)}
                          allowMultiple={true}
                          server={{
                            load: (
                              source,
                              load,
                              error,
                              progress,
                              abort,
                              headers
                            ) => {
                              var myRequest = new Request(source);
                              fetch(myRequest).then(function(response) {
                                response.blob().then(function(myBlob) {
                                  load(myBlob);
                                });
                              });
                            }
                          }}
                          onupdatefiles={fileItems => {
                            this.setState({
                              submitted: false,
                              images: fileItems.map(fileItem => fileItem.file)
                            });
                          }}
                        ></FilePond>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-10">
                        <button
                          onClick={e => this.onUpdate(e)}
                          disabled={this.state.loading}
                          className="btn btn-success"
                        >
                          {this.state.loading ? "Подождите..." : "Сохранить"}
                          <div className="ripple-container"></div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ShowProductContentComponent;
