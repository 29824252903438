import React from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import ShowCategoryContentComponent from './ShowCategoryContentComponent';
import api from '../api';

class ShowCategoryComponent extends React.Component {

    state = {
        loading: true,
        category: null,
    }

    componentDidMount() {
        const {id} = this.props;
        api.categories.get(id).then(category => this.setState({category, loading: false}))
    }

    render() {
        const {loading, category} = this.state;
        return (
            <div className="main-panel">
                <ShowCategoryContentComponent loading={loading} category={category}/>
                <FooterComponent />
            </div>
        );
    }
}

export default ShowCategoryComponent;