import React from 'react';
import {connect} from "react-redux";

class FooterComponent extends React.Component {
    render() {
        return (
            <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">
                      Главная
                    </a>
                  </li>
                  <li>
                    <a href="https://gadget.kz">
                       Магазин
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy; 2020 Gadget. Все права защищены.
              </div>
            </div>
            {this.props.sidebarShown && <div className="close-layer visible"></div>}
          </footer>
        );
    }
}

const mapStateToProps = state => ({
  sidebarShown: state.app.sidebarShown,
});

export default connect(mapStateToProps)(FooterComponent);