import React from 'react';
import FooterComponent from './FooterComponent';
import EditShopPageSliderContentComponent from './EditShopPageSliderContentComponent';

class EditShopPageSliderComponent extends React.Component {
    render() {
        return (
            <div className="main-panel">
                <EditShopPageSliderContentComponent id={this.props.id}/>
                <FooterComponent />
            </div>
        );
    }
}

export default EditShopPageSliderComponent;