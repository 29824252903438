import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { collapseMenu } from "../actions/app";
import { logout } from "../actions/auth";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

class SidebarComponent extends React.Component {
  state = {
    open: false,
    activeLink: "",
    links: [
      {
        name: "Товары",
        icon: "widgets",
        sub: [
          {
            name: "Список",
            icon: "list",
            to: "/"
          },
          {
            name: "Добавить",
            icon: "add_circle",
            to: "/products/add"
          }
        ]
      },
      {
        name: "Продажи",
        icon: "assignment",
        to: "/orders/list"
      },
      {
        name: "Категории",
        icon: "layers",
        sub: [
          {
            name: "Список",
            icon: "list",
            to: "/categories/list"
          },
          {
            name: "Добавить",
            icon: "add_circle",
            to: "/categories/add"
          },
          {
            name: "Дерево",
            icon: "account_tree",
            to: "/categories/tree"
          }
        ]
      },
      {
        name: "Бренды",
        icon: "branding_watermark",
        to: "/brands/list"
      },
      {
        name: "Города",
        icon: "map",
        to: "/cities/list"
      },
      {
        name: "Цели",
        icon: "stars",
        to: "/purposes/list"
      },
      // {
      //   name: "Слайдеры магазина",
      //   icon: "slideshow",
      //   sub: [
      //     {
      //       name: "Список",
      //       icon: "list",
      //       to: "/shop-page-sliders/list"
      //     },
      //     {
      //       name: "Добавить",
      //       icon: "add_circle",
      //       to: "/shop-page-sliders/add"
      //     }
      //   ]
      // },
      {
        name: "Слайдеры главной страницы",
        icon: "home",
        sub: [
          {
            name: "Список",
            icon: "list",
            to: "/home-page-sliders/list"
          },
          {
            name: "Добавить",
            icon: "add_circle",
            to: "/home-page-sliders/add"
          }
        ]
      },
      {
        name: "Пользователи",
        icon: "group",
        to: "/users"
      },
    ]
  };

  handleClick = name => {
    if (this.state.activeLink == name) {
      this.setState({ activeLink: "" });
    }
    if (this.state.activeLink != name) {
      this.setState({ activeLink: name });
    }
  };

  open = name => (this.state.activeLink == name ? true : false);

  onCollapseMenuClick = name => {
    const { collapsedMenu } = this.props;
    if (!collapsedMenu) {
      this.props.collapseMenu(name);
    }
    if (collapsedMenu == name) this.props.collapseMenu(null);
  };

  render() {
    const { collapsedMenu } = this.props;

    return (
      <div className={this.props.sidebarShown?"sidebar show-sidebar":"sidebar"} data-color="rose" data-background-color="black">
        <div className="logo">
          <Link style={{textAlign: "center", display: "block"}} to="/" className="simple-text">
            <div className="photo">
              <img src={require("../assets/img/logo-blue-long.png")} />
            </div>
          </Link>
        </div>
        <div className="sidebar-wrapper">
          <div className="user">
            <div className="photo">
              <img src={require("../assets/img/default-avatar.png")} />
            </div>
            <div className="user-info">
              <Link
                onClick={() => this.onCollapseMenuClick("profile")}
                data-toggle="collapse"
                to="#"
                className="username"
              >
                <span>
                  {this.props.user.name}
                  <b className="caret"></b>
                </span>
              </Link>
              <div
                className={
                  collapsedMenu == "profile" ? "collapse show" : "collapse"
                }
              >
                <ul className="nav">
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      <span className="sidebar-normal">
                        {" "}
                        Редактировать профиль{" "}
                      </span>
                    </a>
                  </li>
                  <li onClick={() => this.props.logout()} className="nav-item">
                    <Link className="nav-link" to="#">
                      <span className="sidebar-normal"> Выйти </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <List
            className={this.props.classes.root}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {this.state.links.map(link => {
              if (!link.sub) {
                return (
                  <Link key={link.name} to={link.to}>
                    <ListItem
                      onClick={() => this.handleClick(link.name)}
                      className={
                        this.open(link.name) ? this.props.classes.selected : ""
                      }
                      button
                    >
                      <ListItemIcon>
                        <Icon className={this.props.classes.white}>
                          {link.icon}
                        </Icon>
                      </ListItemIcon>
                      <ListItemText
                        className={this.props.classes.white}
                        primary={link.name}
                      />
                    </ListItem>
                  </Link>
                );
              }

              if (link.sub) {
                return [
                  <ListItem
                    className={
                      this.open(link.name) ? this.props.classes.selected : ""
                    }
                    key={link.name}
                    button
                    onClick={() => this.handleClick(link.name)}
                  >
                    <ListItemIcon>
                      <Icon className={this.props.classes.white}>
                        {link.icon}
                      </Icon>
                    </ListItemIcon>
                    <ListItemText
                      className={this.props.classes.white}
                      primary={link.name}
                    />
                    {this.open(link.name) ? (
                      <ExpandLess className={this.props.classes.white} />
                    ) : (
                      <ExpandMore className={this.props.classes.white} />
                    )}
                  </ListItem>,
                  <Collapse
                    key={`${link.name}-collapse`}
                    in={this.open(link.name)}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {link.sub.map(sub => (
                        <Link key={sub.name} to={sub.to}>
                          <ListItem
                            className={this.props.classes.nested}
                            button
                          >
                            <ListItemIcon>
                              <Icon className={this.props.classes.white}>
                                {sub.icon}
                              </Icon>
                            </ListItemIcon>
                            <ListItemText
                              className={this.props.classes.white}
                              primary={sub.name}
                            />
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  </Collapse>
                ];
              }
            })}
          </List>
        </div>
        <div className="sidebar-background"></div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {},
  white: {
    color: "white"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  selected: {
    backgroundColor: "#d81b60"
  }
});

const mapStateToProps = state => ({
  user: state.auth.user,
  sidebarShown: state.app.sidebarShown,
  collapsedMenu: state.app.collapsedMenu
});

export default withStyles(styles)(
  connect(mapStateToProps, { collapseMenu, logout })(SidebarComponent)
);
