import React from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import AddProductContentComponent from './AddProductContentComponent';

class AddProductComponent extends React.Component {
    render() {
        return (
            <div className="main-panel">
                <AddProductContentComponent />
                <FooterComponent />
            </div>
        );
    }
}

export default AddProductComponent;