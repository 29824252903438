import React from "react";
import api from "../api";
import { withRouter } from "react-router-dom";
import imgPath from "../utils/imgPath";
import price from "../utils/price";
import MaterialTable from "material-table";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

class UsersContentComponent extends React.Component {
  state = {
    users: [],
    loaded: false,
    columns: [
      { title: "ID", field: "id" },
      { title: "Имя и Фамилия", field: "name" },
      { title: "Email", field: "email" },
      {
        title: "Номер телефона",
        field: "phone",
        render: rowData => rowData.phone ? rowData.phone : "Неопределно"
      },
      {
        title: "Город",
        field: "city",
        render: rowData => rowData.city ? rowData.city.name : "Неопределно"
      },
      {
        title: "Адрес",
        field: "address",
        render: rowData => rowData.address ? rowData.address : "Неопределно"
      },


      {
        title: "Зарегистрирован",
        field: "created_at",
        render: rowData => this.renderCreatedAtTD(rowData.created_at)
      }
    ],
    data: []
  };


  renderCreatedAtTD = date => {
    const event = new Date(date);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return event.toLocaleDateString("ru-RU", options);
  };



  componentDidMount() {
    api.users
      .getAll()
      .then(users => this.setState({ data: users }))
      .finally(() => this.setState({ loaded: true }));
  }

  render() {
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header card-header-icon card-header-rose">
                  <div className="card-icon">
                    <i className="material-icons">assignment</i>
                  </div>
                  <h4 className="card-title ">Пользователи</h4>
                </div>
                <div className="card-body">
                  <MaterialTable
                    options={{
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 30]
                    }}
                    isLoading={!this.state.loaded}
                    localization={{
                      pagination: {
                        labelDisplayedRows: "{from}-{to} из {count}",
                        labelRowsSelect: "строк",
                        firstAriaLabel: "Первая страница",
                        firstTooltip: "Первая страница",
                        previousAriaLabel: "Пред. страница",
                        previousTooltip: "Пред. страница",
                        nextAriaLabel: "След. страница",
                        nextTooltip: "След. страница",
                        lastAriaLabel: "Последняя страница",
                        lastTooltip: "Последняя страница"
                      },
                      toolbar: {
                        nRowsSelected: "{0} строк выбрано",
                        searchPlaceholder: "Поиск",
                        searchTooltip: "Поиск"
                      },
                      header: {
                        actions: "Действия"
                      },
                      body: {
                        emptyDataSourceMessage: "Нет данных",
                        filterRow: {
                          filterTooltip: "Фильтр"
                        },
                        editRow: {
                          deleteText: "Вы точно хотите удалить?",
                          cancelTooltip: "Отмена",
                          saveTooltip: "Сохранить"
                        },
                        addTooltip: "Добавить",
                        deleteTooltip: "Удалить",
                        editTooltip: "Редактировать"
                      }
                    }}
                    title="Список клиентов"
                    columns={this.state.columns}
                    data={this.state.data}
        
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UsersContentComponent;
