import React from 'react';
import SidebarComponent from '../components/SidebarComponent';
import HomePageSlidersListComponent from '../components/HomePageSlidersListComponent';
import HeaderComponent from '../components/HeaderComponent';

class HomePageSlidersListPage extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <SidebarComponent location={this.props.location}/>
                <HeaderComponent/>
                <HomePageSlidersListComponent location={this.props.location}/>
            </div>
        );
    }
}

export default HomePageSlidersListPage;