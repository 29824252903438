import React from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import EditCategoryContentComponent from './EditCategoryContentComponent';

class EditCategoryComponent extends React.Component {
    render() {
        return (
            <div className="main-panel">
                <EditCategoryContentComponent id={this.props.id}/>
                <FooterComponent />
            </div>
        );
    }
}

export default EditCategoryComponent;