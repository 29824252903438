import React from 'react';
import SidebarComponent from '../components/SidebarComponent';
import CategoriesComponent from '../components/CategoriesComponent';
import HeaderComponent from '../components/HeaderComponent';

class CategoriesPage extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <SidebarComponent location={this.props.location}/>
                <HeaderComponent/>
                <CategoriesComponent />
            </div>
        );
    }
}

export default CategoriesPage;