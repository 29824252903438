import React from 'react';
import SidebarComponent from '../components/SidebarComponent';
import EditHomePageSliderComponent from '../components/EditHomePageSliderComponent';
import HeaderComponent from '../components/HeaderComponent';

class EditHomePageSliderPage extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <SidebarComponent location={this.props.location}/>
                <HeaderComponent/>
                <EditHomePageSliderComponent id={this.props.match.params.id}/>
            </div>
        );
    }
}

export default EditHomePageSliderPage;