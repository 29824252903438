import React from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';

class CategoriesComponent extends React.Component {
    render() {
        return (
            <div className="main-panel">
                <HeaderComponent title="Категории" />
                <FooterComponent />
            </div>
        );
    }
}

export default CategoriesComponent;