import React from 'react';
import SidebarComponent from '../components/SidebarComponent';
import BrandsListComponent from '../components/BrandsListComponent';
import HeaderComponent from '../components/HeaderComponent';

class BrandsListPage extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <SidebarComponent location={this.props.location}/>
                <HeaderComponent/>
                <BrandsListComponent location={this.props.location}/>
            </div>
        );
    }
}

export default BrandsListPage;