import React from 'react';
import HeaderComponent from './HeaderComponent';
import DashboardContentComponent from './DashboardContentComponent';
import FooterComponent from './FooterComponent';

class DashboardComponent extends React.Component {
    render() {
        return (
            <div className="main-panel">
                <HeaderComponent title="Главная"/>
                <DashboardContentComponent/>
                <FooterComponent/>
            </div>
        );
    }
}

export default DashboardComponent;