import React from 'react';
import FooterComponent from './FooterComponent';
import EditSliderContentComponent from './EditSliderContentComponent';

class EditSliderComponent extends React.Component {
    render() {
        return (
            <div className="main-panel">
                <EditSliderContentComponent id={this.props.id}/>
                <FooterComponent />
            </div>
        );
    }
}

export default EditSliderComponent;