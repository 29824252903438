import React from 'react';
import LoginComponent from '../components/LoginComponent';


class LoginPage extends React.Component {
  render() {
    return (
      <div className="wrapper wrapper-full-page">
        <div className="page-header login-page header-filter" filter-color="black">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
                <LoginComponent />
              </div>
            </div>
          </div>
          <footer className="footer">
            <div className="container">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="/">
                       Главная
                    </a>
                  </li>
                  <li>
                    <a href="https://gadget.kz">
                      Магазин
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right">
                &copy; 2020 Gadget. Все права защищены.
              </div>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default LoginPage;