import React from 'react';
import FooterComponent from './FooterComponent';
import UsersContentComponent from './UsersContentComponent';

class UsersComponent extends React.Component {
    render() {
        return(
            <div className="main-panel">
                <UsersContentComponent location={this.props.location}/>
                <FooterComponent/>
            </div>
        );
    }
}

export default UsersComponent;