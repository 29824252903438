import React from "react";
import Select from "react-select";
import api from "../api";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import imgPath from "../utils/imgPath";
import { withRouter } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

class EditCategoryContentComponent extends React.Component {
  state = {
    categories: [],
    selectedCategory: null,
    image: null,
    icon: null,
    name: "",
    submitted: false,
    error: false,
    loading: false,
    inMenu: true,
    inBanner: false,
    created: false,
    openDialog: false,
    autoCompleteSelectedValue: null
  };

  componentDidMount() {
    const { id } = this.props;

    api.categories.get(id).then(category => {
      this.setState({
        name: category.name,
        inMenu: category.show_in_shop_menu,
        inBanner: category.show_in_banner
      });

      api.categories.getAll().then(categories => {
        if (category.parent) {
          const autoCompleteSelectedValue = categories.filter(
            p => p.id == category.parent.id
          )[0];
          this.setState({
            selectedCategory: category.parent.id,
            autoCompleteSelectedValue
          });
        }
        this.setState({ categories });
      });

      if (category.thumbnail) {
        this.setState({
          image: {
            source: imgPath(category.thumbnail),
            options: {
              type: "local"
            }
          }
        });
      }

      if (category.icon) {
        this.setState({
          icon: {
            source: imgPath(category.icon),
            options: {
              type: "local"
            }
          }
        });
      }
    });

    if (this.props.location.state && this.props.location.state.status && this.props.location.state.status == "created") {
        this.setState({created: true});
    }
  }

  onNameChange = e => {
    this.setState({
      submitted: false,
      name: e.target.value
    });
  };

  onParentCategoryChange = selectedParentCategory => {
    this.setState({ selectedParentCategory, submitted: false });
  };

  onSubmit = e => {
    e.preventDefault();
    const error = this.validate();
    this.setState({ error, submitted: true, loading: true });
    if (error) this.setState({ loading: false, openDialog: true });
    if (!error) {
      const formData = new FormData();

      formData.append("name", this.state.name);
      formData.append("in_menu", this.state.inMenu);
      formData.append("in_banner", this.state.inBanner);

      if (this.state.selectedCategory) {
        formData.append("parent_id", this.state.selectedCategory);
      }

      if (this.state.icon) {

        const file = new File([this.state.icon], this.state.icon.name);
        formData.append("icon", file);

      }

      if (this.state.image) {
        const file = new File([this.state.image], this.state.image.name);
        formData.append("thumbnail", file);
      }

      api.categories
        .update(this.props.id, formData)
        .then(res => {
          this.setState({
            created: true,
            loading: false,
            submitted: false,
            error: null
          });
        })
        .catch(err => {
          this.setState({
            error: "Произошла непредвиденная ошибка",
            openDialog: true,
            loading: false
          });
          if (err.response && err.response.data & err.response.data.error) {
            this.setState({
              error: err.response.data.error,
              openDialog: true,
              loading: false
            });
          }
        });
    }
  };

  validate = () => {
    let error = false;
    if (this.state.name == "")
      error = "Наименование категории - обязательное поле.";
    return error;
  };

  render() {
    return (
      <div className="content">
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={this.state.created}
          autoHideDuration={6000}
          onClose={() => this.setState({ created: false })}
          message="Категория успешно сохранена"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => this.setState({ created: false })}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />

        <Dialog
          open={this.state.openDialog}
          onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Произашла ошибка"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ openDialog: false })}
              color="primary"
              autoFocus
            >
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card ">
                <div className="card-header card-header-rose card-header-text">
                  <div className="card-text">
                    <h4 className="card-title">Данные категории</h4>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Наименование категории
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <input
                          onChange={e => this.onNameChange(e)}
                          type="text"
                          value={this.state.name}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Картинка категории
                    </label>
                    <div className="col-sm-10">
                      <FilePond
                        acceptedFileTypes={['image/png','image/jpeg','image/jpg','image/png']}
                        files={this.state.image ? [this.state.image] : null}
                        ref={image => (this.pond = image)}
                        allowMultiple={false}
                        server={{
                          load: (
                            source,
                            load,
                            error,
                            progress,
                            abort,
                            headers
                          ) => {
                            var myRequest = new Request(source);
                            fetch(myRequest).then(function(response) {
                              response.blob().then(function(myBlob) {
                                load(myBlob);
                              });
                            });
                          }
                        }}
                        onupdatefiles={fileItems => {
                          this.setState({
                            submitted: false,
                            image: fileItems[0] ? fileItems[0].file : null
                          });
                        }}
                      ></FilePond>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Иконка категории
                    </label>
                    <div className="col-sm-10">
                      <FilePond
                        acceptedFileTypes={['image/png','image/jpeg','image/jpg','image/png']}
                        files={this.state.icon ? [this.state.icon] : null}
                        ref={image => (this.pond = image)}
                        allowMultiple={false}
                        server={{
                          load: (
                            source,
                            load,
                            error,
                            progress,
                            abort,
                            headers
                          ) => {
                            var myRequest = new Request(source);
                            fetch(myRequest).then(function(response) {
                              response.blob().then(function(myBlob) {
                                load(myBlob);
                              });
                            });
                          }
                        }}
                        onupdatefiles={fileItems => {
                          this.setState({
                            submitted: false,
                            icon: fileItems[0] ? fileItems[0].file : null
                          });
                        }}
                      ></FilePond>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Добавить в меню магазина
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <Switch
                          checked={this.state.inMenu}
                          onChange={() =>
                            this.setState({ inMenu: !this.state.inMenu })
                          }
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Добавить в блок категории на главном экране
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <Switch
                          checked={this.state.inBanner}
                          onChange={() =>
                            this.setState({ inBanner: !this.state.inBanner })
                          }
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Родитель категории
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <Autocomplete
                          id="combo-box-demo"
                          multiple={false}
                          options={this.state.categories}
                          value={this.state.autoCompleteSelectedValue}
                          getOptionLabel={option => option.name}
                          onChange={(e, v) => {
                            this.setState({
                              selectedCategory: v.id,
                              autoCompleteSelectedValue: v
                            });
                          }}
                          renderInput={params => (
                            <TextField {...params} label="Родитель" fullWidth />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-10">
                      <button
                        onClick={e => this.onSubmit(e)}
                        disabled={this.state.loading}
                        className="btn btn-success"
                      >
                        {this.state.loading
                          ? "Подождите..."
                          : "Сохранить категорию"}
                        <div className="ripple-container"></div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditCategoryContentComponent);
