import React from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import BrandsListContentComponent from './BrandsListContentComponent';

class BrandsListComponent extends React.Component {
    render() {
        return(
            <div className="main-panel">
                <BrandsListContentComponent location={this.props.location}/>
                <FooterComponent/>
            </div>
        );
    }
}

export default BrandsListComponent;