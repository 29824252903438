import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../actions/app";

class HeaderComponent extends React.Component {
  toggleSidebar = () => {
    const {sidebarShown} = this.props;
    if (!sidebarShown) this.props.toggleSidebar(true);
    if (sidebarShown) this.props.toggleSidebar(false);
  };
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
        <div className="container-fluid">
          <div className="navbar-wrapper"></div>
          <button
            onClick={() => this.toggleSidebar()}
            className={this.props.sidebarShown?"navbar-toggler toggled":"navbar-toggler"}
            type="button"
            style={{marginTop: 5}}
            data-toggle="collapse"
            aria-controls="navigation-index"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="navbar-toggler-icon icon-bar"></span>
            <span className="navbar-toggler-icon icon-bar"></span>
            <span className="navbar-toggler-icon icon-bar"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className="nav-link"
                  id="navbarDropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="material-icons">notifications</i>
                  {/* <span className="notification">5</span> */}
                  <p className="d-lg-none d-md-block">Some Actions</p>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a className="dropdown-item" href="#">
                    Mike John responded to your email
                  </a>
                  <a className="dropdown-item" href="#">
                    You have 5 new tasks
                  </a>
                  <a className="dropdown-item" href="#">
                    You're now friend with Andrew
                  </a>
                  <a className="dropdown-item" href="#">
                    Another Notification
                  </a>
                  <a className="dropdown-item" href="#">
                    Another One
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  sidebarShown: state.app.sidebarShown,
});

export default connect(mapStateToProps, {toggleSidebar})(HeaderComponent);
