import React from 'react';
import SidebarComponent from '../components/SidebarComponent';
import ProductsListComponent from '../components/ProductsListComponent';
import HeaderComponent from '../components/HeaderComponent';

class ProductsListPage extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <SidebarComponent location={this.props.location}/>
                <HeaderComponent/>
                <ProductsListComponent location={this.props.location}/>
            </div>
        );
    }
}

export default ProductsListPage;