import React from "react";
import SidebarComponent from "../components/SidebarComponent";
import ShowProductComponent from "../components/ShowProductComponent";
import HeaderComponent from '../components/HeaderComponent';

class ShowProductPage extends React.Component {
  render() {
    return (
      <div className="wrapper">
        <SidebarComponent location={this.props.location} />
        <HeaderComponent/>
        <ShowProductComponent id={this.props.match.params.id} />
      </div>
    );
  }
}

export default ShowProductPage;
