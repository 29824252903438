import React from "react";
import api from "../api";

class AddBrandContentComponent extends React.Component {
  render() {
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header card-header-rose card-header-text">
                  <div className="card-text">
                    <h4 className="card-title">Данные брэнда</h4>
                  </div>
                </div>
                <div className="card-body"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddBrandContentComponent;
