import React from "react";
import Select from "react-select";
import api from "../api";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

class AddSliderContentComponent extends React.Component {
  state = {
    categories: [],
    selectedCategory: null,
    selectedSubCategory: null,
    selectedSubSubCategory: null,
    products: [],
    openDialog: false,
    selectedProduct: null,
    image: null,
    position: "",
    submitted: false,
    error: false,
    loading: false
  };

  componentDidMount() {
    api.categories.getAllWithChilds().then(categories => {
      this.setState({ categories });
    });
    api.products.getAll().then(products => {
      this.setState({ products });
    });
  }

  onPositionChange = e => {
    this.setState({
      submitted: false,
      position: e.target.value
    });
  };

  onCategoryChange = e => {
    const category = this.state.categories.filter(
      category => category.id == e.target.value
    )[0];
    this.setState({ selectedCategory: category, submitted: false });
  };

  onSubCategoryChange = e => {
    const category = this.state.selectedCategory.childs.filter(
      category => category.id == e.target.value
    );
    if (category.length > 0) {
      this.setState({ selectedSubCategory: category[0], submitted: false });
    }
  };

  onSubSubCategoryChange = e => {
    this.setState({ selectedSubSubCategory: e.target.value, submitted: false });
  };

  onProductChange = e => {
    this.setState({ selectedProduct: e.target.value, submitted: false });
  };

  onSubmit = e => {
    e.preventDefault();
    const error = this.validate();
    this.setState({ error, submitted: true, loading: true });
    if (error) this.setState({ loading: false, openDialog: true });
    if (!error) {
      const formData = new FormData();
      const image = this.state.image;
      formData.append("image", image);

      if (this.state.position != "") {
        formData.append("position", this.state.position);
      }

      if (this.state.selectedSubSubCategory) {
        formData.append("category_id", this.state.selectedSubSubCategory);
      }

      if (this.state.selectedProduct) {
        formData.append("product_id", this.state.selectedProduct);
      }

      api.sliders
        .store(formData)
        .then(res => this.props.history.push('/sliders/edit/' + res.id))
        .catch(err => {
          this.setState({ error: "Произошла непредвиденная ошибка", openDialog: true, loading: false });
          if (err.response && err.response.data & err.response.data.error) {
            this.setState({ error: err.response.data.error, openDialog: true, loading: false });
          }
        })
    }
  };

  validate = () => {
    let error = false;
    if (!this.state.image) error = "Картинка слайдера - обязательное поле.";
    if (!this.state.selectedSubSubCategory && !this.state.selectedProduct)
      error = "Выберите категорию или товар для слайдера.";
    return error;
  };

  render() {
    return (
      <div className="content">
        <Dialog
          open={this.state.openDialog}
          onClose={() => this.setState({ openDialog: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Произашла ошибка"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ openDialog: false })}
              color="primary"
              autoFocus
            >
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card ">
                <div className="card-header card-header-rose card-header-text">
                  <div className="card-text">
                    <h4 className="card-title">Добавить слайдер</h4>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Позиция слайдера
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <input
                          onChange={e => this.onPositionChange(e)}
                          type="number"
                          className="form-control"
                          placeholder="необязательно"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">Картинка</label>
                    <div className="col-sm-10">
                      <FilePond
                        acceptedFileTypes={['image/png','image/jpeg','image/jpg','image/png']}
                        ref={image => (this.pond = image)}
                        allowMultiple={false}
                        onupdatefiles={fileItems => {
                          this.setState({
                            submitted: false,
                            image: fileItems[0] ? fileItems[0].file : null
                          });
                        }}
                      ></FilePond>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Категория слайдера (при нажатии на слайдер - пользователь
                      будет перенравляться на на данную категорию)
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <select
                          onChange={this.onCategoryChange}
                          className="form-control"
                        >
                          <option value={null}>Выберите категорию</option>
                          {this.state.categories.map(category => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {this.state.selectedCategory &&
                    this.state.selectedCategory.childs && (
                      <div className="row">
                        <label className="col-sm-2 col-form-label">
                          Подкатегория
                        </label>
                        <div className="col-sm-10">
                          <div className="form-group">
                            <select
                              onChange={this.onSubCategoryChange}
                              className="form-control"
                            >
                              <option value={null}>Выберите категорию</option>
                              {this.state.selectedCategory.childs.map(
                                category => (
                                  <option key={category.id} value={category.id}>
                                    {category.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                  {this.state.selectedSubCategory &&
                    this.state.selectedSubCategory.childs && (
                      <div className="row">
                        <label className="col-sm-2 col-form-label">
                          Еще подкатегория
                        </label>
                        <div className="col-sm-10">
                          <div className="form-group">
                            <select
                              onChange={this.onSubSubCategoryChange}
                              className="form-control"
                            >
                              <option value={null}>Выберите категорию</option>
                              {this.state.selectedSubCategory.childs.map(
                                category => (
                                  <option key={category.id} value={category.id}>
                                    {category.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Если категория не будет указано, нужно указать товар
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <Autocomplete
                          id="combo-box-demo"
                          multiple={false}
                          options={this.state.products}
                          getOptionLabel={option => option.name}
                          onChange={(e, v) => {
                            this.setState({ selectedProduct: v.id });
                          }}
                          renderInput={params => (
                            <TextField {...params} label="Товар" fullWidth />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-10">
                      <button
                        onClick={e => this.onSubmit(e)}
                        disabled={this.state.loading}
                        className="btn btn-success"
                      >
                        {this.state.loading
                          ? "Подождите..."
                          : "Добавить слайдер"}
                        <div className="ripple-container"></div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddSliderContentComponent);
