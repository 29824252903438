import React from "react";
import SidebarComponent from "../components/SidebarComponent";
import ShowCategoryComponent from "../components/ShowCategoryComponent";
import HeaderComponent from '../components/HeaderComponent';

class ShowCategoryPage extends React.Component {
  render() {
    return (
      <div className="wrapper">
        <SidebarComponent location={this.props.location} />
        <HeaderComponent/>
        <ShowCategoryComponent id={this.props.match.params.id} />
      </div>
    );
  }
}

export default ShowCategoryPage;
