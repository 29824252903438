import React from 'react';
import {connect} from "react-redux";
import FooterComponent from './FooterComponent';
import ProductsListContentComponent from './ProductsListContentComponent';

class ProductsListComponent extends React.Component {
    render() {
        return(
            <div className="main-panel">
                <ProductsListContentComponent location={this.props.location}/>
                <FooterComponent/>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    sidebarShown: state.app.sidebarShown,
  });

export default connect(mapStateToProps)(ProductsListComponent);