import React from "react";
import MaterialTable from "material-table";
import api from "../api";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class PurposesListContentComponent extends React.Component {
  state = {
    loaded: false,
    error: null,
    openDialog: false,
    columns: [
      { title: "ID", field: "id", editable: false },
      { title: "Наименование", field: "name" }
    ],
    data: []
  };

  componentDidMount() {
    api.purposes
      .getAll()
      .then(purposes => this.setState({ data: purposes }))
      .finally(() => this.setState({ loaded: true }));
  }

  render() {
    return (
      <div className="content">
        <div className="container-fluid">
          <Dialog
            open={this.state.openDialog}
            onClose={() => this.setState({ openDialog: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Произашла ошибка"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.error}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ openDialog: false })}
                color="primary"
                autoFocus
              >
                Закрыть
              </Button>
            </DialogActions>
          </Dialog>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header card-header-icon card-header-rose">
                  <div className="card-icon">
                    <i className="material-icons">assignment</i>
                  </div>
                  <h4 className="card-title ">Цели</h4>
                </div>
                <div className="card-body">
                  <MaterialTable
                    options={{
                      pageSize: 10,
                      pageSizeOptions: [10, 20, 30]
                    }}
                    isLoading={!this.state.loaded}
                    localization={{
                      pagination: {
                        labelDisplayedRows: "{from}-{to} из {count}",
                        labelRowsSelect: "строк",
                        firstAriaLabel: "Первая страница",
                        firstTooltip: "Первая страница",
                        previousAriaLabel: "Пред. страница",
                        previousTooltip: "Пред. страница",
                        nextAriaLabel: "След. страница",
                        nextTooltip: "След. страница",
                        lastAriaLabel: "Последняя страница",
                        lastTooltip: "Последняя страница",
                      },
                      toolbar: {
                        nRowsSelected: "{0} строк выбрано",
                        searchPlaceholder: "Поиск",
                        searchTooltip: "Поиск"
                      },
                      header: {
                        actions: "Действия"
                      },
                      body: {
                        emptyDataSourceMessage: "Нет данных",
                        filterRow: {
                          filterTooltip: "Фильтр"
                        },
                        editRow: {
                          deleteText: "Вы точно хотите удалить?",
                          cancelTooltip: "Отмена",
                          saveTooltip: "Сохранить"
                        },
                        addTooltip: "Добавить",
                        deleteTooltip: "Удалить",
                        editTooltip: "Редактировать"
                      }
                    }}
                    title="Таблица цели"
                    columns={this.state.columns}
                    data={this.state.data}
                    editable={{
                      onRowAdd: newData =>
                        api.purposes
                          .store(newData)
                          .then(data => {
                            const created = {
                              id: data.id,
                              name: newData.name
                            };


                            this.setState(prevState => ({
                              data: [...prevState.data, created]
                            }));
                          })
                          .catch(err =>
                            this.setState({
                              openDialog: true,
                              error:
                                "Цель с таким наименованием уже существует"
                            })
                          ),
                      onRowUpdate: (newData, oldData) =>
                        api.purposes
                          .update(oldData.id, newData)
                          .then(res => {
                            if (oldData) {
                              this.setState(prevState => {
                                const data = [...prevState.data];
                                data[data.indexOf(oldData)] = newData;
                                return { ...prevState, data };
                              });
                            }
                          })
                          .catch(err =>
                            this.setState({
                              openDialog: true,
                              error:
                                "Цель с таким наименованием уже существует"
                            })
                          ),
                      onRowDelete: oldData =>
                        api.purposes
                          .delete(oldData.id)
                          .then(res => {
                            this.setState(prevState => {
                              const data = [...prevState.data];
                              data.splice(data.indexOf(oldData), 1);
                              return { ...prevState, data };
                            });
                          })
                          .catch(err =>
                            this.setState({
                              openDialog: true,
                              error: "Неудалось удалить цель"
                            })
                          )
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PurposesListContentComponent;
