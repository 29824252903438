import React from 'react';

class DashboardContentComponent extends React.Component {
    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-10 ml-auto mr-auto">
                            <div className="card card-calendar">
                                <div className="card-body ">
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardContentComponent;