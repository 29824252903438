import React from 'react';
import FooterComponent from './FooterComponent';
import ShowProductContentComponent from './ShowProductContentComponent';

class ShowProductComponent extends React.Component {
    render() {
        return (
            <div className="main-panel">
                <ShowProductContentComponent id={this.props.id}/>
                <FooterComponent />
            </div>
        );
    }
}

export default ShowProductComponent;