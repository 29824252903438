import React from "react";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";
import { withStyles } from "@material-ui/core/styles";
import api from "../api";

class CategoriesTreeViewContentComponent extends React.Component {
  state = {
    categories: [],
    expanded: []
  };

  handleChange = (event, nodes) => {
    this.setState({ expanded: nodes });
  };

  componentDidMount() {
    api.categories
      .getAllWithChilds()
      .then(categories => this.setState({ categories }));
  }

  render() {
    console.log(this.state.categories);
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header card-header-icon card-header-rose">
                  <div className="card-icon">
                    <i className="material-icons">assignment</i>
                  </div>
                  <h4 className="card-title ">Категории</h4>
                </div>
                <div className="card-body">
                  <TreeView
                    className={this.props.classes.root}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={this.state.expanded}
                    onNodeToggle={this.handleChange}
                  >
                    {this.state.categories.map((category, i) => (
                      <TreeItem key={category.id} nodeId={category.id} label={category.name}>
                          {category.childs && category.childs.map(child => <TreeItem key={child.id} nodeId={child.id} label={child.name}>
                              {child.childs && child.childs.map(child => <TreeItem key={child.id} nodeId={child.id} label={child.name}/>)}
                          </TreeItem>)}
                      </TreeItem>
                    ))}
                  </TreeView>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    height: 700,
    flexGrow: 1,
    maxWidth: 700
  }
});

export default withStyles(styles)(CategoriesTreeViewContentComponent);
