import { MENU_COLLAPSED, SIDEBAR_TOGGLED } from "../types";

export const menuCollapsed = menu => ({
  type: MENU_COLLAPSED,
  collapsedMenu: menu
});

export const sidebarToggled = shown => ({
  type: SIDEBAR_TOGGLED,
  shown
});

export const collapseMenu = menu => dispatch => dispatch(menuCollapsed(menu));
export const toggleSidebar = shown => dispatch =>
  dispatch(sidebarToggled(shown));
