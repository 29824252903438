import React from "react";
import Select from "react-select";
import api from "../api";
import { withRouter } from "react-router-dom";
import price from "../utils/price";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

class EditOrderContentComponent extends React.Component {
  state = {
    order: null,
    error: null,
    created: false,
    loading: false,
    statusses: [
      {
        label: "Новый",
        value: "created"
      },
      {
        label: "Ошибка",
        value: "failed"
      },
      {
        label: "Оплачено",
        value: "payed"
      },
      {
        label: "Доставлено",
        value: "delivered"
      },
      {
        label: "В пути",
        value: "delivering"
      },
      {
        label: "Возврат",
        value: "return"
      }
    ],
    updatedStatus: null
  };

  componentDidMount() {
    const id = this.props.id;
    api.orders
      .get(id)
      .then(order => {
        this.setState({ order });
        const stObj = this.getStatusObj(order.status);
        this.setState({ updatedStatus: stObj });
      })
      .catch(err => {
        let msg = "произошла непредвиденная ошибка, попробуйте позже";
        if (err.response && err.response.data && err.response.data.error) {
          msg = err.response.data.error;
        }
        this.setState({ error: msg });
      });
  }


  renderCreatedAt = date => {
    const event = new Date(date);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return event.toLocaleDateString("ru-RU", options);
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });
    api.orders
      .updateStatus(this.props.id, this.state.updatedStatus.value)
      .then(data => {
        this.setState({ loading: false, created: true });
      })
      .catch(err => {
        let msg = "произошла непредвиденная ошибка, попробуйте позже";
        if (err.response && err.response.data && err.response.data.error) {
          msg = err.response.data.error;
        }
        this.setState({ error: msg });
      });
  };

  getStatusObj = status => {
    let label = "";
    switch (status) {
      case "created":
        label = "Новый";
        break;
      case "failed":
        label = "Ошибка";
        break;
      case "payed":
        label = "Оплачено";
        break;
      case "delivered":
        label = "Доставлено";
        break;
      case "delivering":
        label = "В пути";
        break;
      case "return":
        label = "Возврат";
        break;

      default:
        label = "Неизвестно";
        break;
    }
    return { label, value: status };
  };

  render() {
    const { order, error } = this.state;
    if (!order)
      return (
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <p>Идет загрузка...</p>
              </div>
            </div>
          </div>
        </div>
      );

    if (!order && error) {
      return (
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <p>Ошибка: {error}</p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="content">
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={this.state.created}
          autoHideDuration={6000}
          onClose={() => this.setState({ created: false })}
          message="Статус успешно обновлен"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => this.setState({ created: false })}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card ">
                <div className="card-header card-header-rose card-header-text">
                  <div className="card-text">
                    <h4 className="card-title">Детали заказа</h4>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Номер заказа
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <input
                          value={order.id}
                          disabled
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Сумма заказа
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <input
                          value={price(order.amount)}
                          disabled
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                        Статус заказа
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <Autocomplete
                          id="combo-box-demo"
                          multiple={false}
                          options={this.state.statusses}
                          value={this.state.updatedStatus}
                          getOptionLabel={option => option.label}
                          onChange={(e, v) => {
                            this.setState({
                              updatedStatus: v
                            });
                          }}
                          renderInput={params => (
                            <TextField {...params} label="Статус" fullWidth />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Имя клиента
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <input
                          value={order.user.name}
                          disabled
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Номер клиента
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <input
                          value={order.user.phone}
                          disabled
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Email клиента
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <input
                          value={order.user.email}
                          disabled
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">Город</label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <input
                          value={order.user.city.name}
                          disabled
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">Адрес</label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <input
                          value={order.user.address}
                          disabled
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Список товаров
                    </label>
                    <div className="col-sm-10">
                      {order.products.map(product => (
                        <div className="form-group">
                          <input
                            value={
                              product.name +
                              " * " +
                              product.quantity +
                              " шт. = " +
                              price(product.quantity * product.price)
                            }
                            disabled
                            type="text"
                            className="form-control"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">Создано</label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <input
                          value={this.renderCreatedAt(order.created_at)}
                          disabled
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-10">
                      <button
                        onClick={e => this.onSubmit(e)}
                        disabled={this.state.loading}
                        className="btn btn-success"
                      >
                        {this.state.loading
                          ? "Подождите..."
                          : "Обновить статус заказа"}
                        <div className="ripple-container"></div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditOrderContentComponent);
