import React from 'react';
import SidebarComponent from '../components/SidebarComponent';
import AddBrandComponent from '../components/AddBrandComponent';
import HeaderComponent from '../components/HeaderComponent';

class AddBrandPage extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <SidebarComponent location={this.props.location}/>
                <HeaderComponent/>
                <AddBrandComponent />
            </div>
        );
    }
}

export default AddBrandPage;