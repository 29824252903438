import React from 'react';
import SidebarComponent from '../components/SidebarComponent';
import SlidersListComponent from '../components/SlidersListComponent';
import HeaderComponent from '../components/HeaderComponent';

class SlidersListPage extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <SidebarComponent location={this.props.location}/>
                <HeaderComponent/>
                <SlidersListComponent location={this.props.location}/>
            </div>
        );
    }
}

export default SlidersListPage;