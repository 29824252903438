import React from "react";
import api from "../api";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import MaterialTable from "material-table";
import Switch from "@material-ui/core/Switch";

class CategoriesListContentComponent extends React.Component {
  state = {
    loaded: false,
    columns: [
      { title: "ID", field: "id" },
      { title: "Наименование", field: "name" },
      {
        title: "В меню магазина",
        field: "show_in_shop_menu",
        render: rowData => (
          <Switch
            checked={rowData.show_in_shop_menu}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        )
      },
      {
        title: "В блоке на главном экране",
        field: "show_in_banner",
        render: rowData => (
          <Switch
            checked={rowData.show_in_banner}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        )
      }
    ],
    data: []
  };

  componentDidMount() {
    api.categories
      .getAll()
      .then(categories => this.setState({ data: categories }))
      .finally(() => this.setState({ loaded: true }));
  }

  onDeleteClick = id => {
    Swal.fire({
      title: "Вы уверены?",
      text: "После удаление - все категории товара будут удалены",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Удалить!"
    }).then(result => {
      if (result.value) {
        api.categories.delete(id).then(res => {
          api.categories
            .getAll()
            .then(categories => this.setState({ categories }));
          Swal.fire("Удалено!", "Категория была удалена", "success");
        });
      }
    });
  };

  render() {
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header card-header-icon card-header-rose">
                    <div className="card-icon">
                      <i className="material-icons">assignment</i>
                    </div>
                    <h4 className="card-title ">Категорий</h4>
                  </div>
                  <div className="card-body">
                    <MaterialTable
                      editable={{
                        onRowDelete: oldData =>
                          api.categories
                            .delete(oldData.id)
                            .then(res => {
                              this.setState(prevState => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                              });
                            })
                            .catch(err =>
                              this.setState({
                                openDialog: true,
                                error: "Неудалось удалить категорию"
                              })
                            )
                      }}
                      options={{
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 30]
                      }}
                      isLoading={!this.state.loaded}
                      localization={{
                        pagination: {
                          labelDisplayedRows: "{from}-{to} из {count}",
                          labelRowsSelect: "строк",
                          firstAriaLabel: "Первая страница",
                          firstTooltip: "Первая страница",
                          previousAriaLabel: "Пред. страница",
                          previousTooltip: "Пред. страница",
                          nextAriaLabel: "След. страница",
                          nextTooltip: "След. страница",
                          lastAriaLabel: "Последняя страница",
                          lastTooltip: "Последняя страница"
                        },
                        toolbar: {
                          nRowsSelected: "{0} строк выбрано",
                          searchPlaceholder: "Поиск",
                          searchTooltip: "Поиск"
                        },
                        header: {
                          actions: "Действия"
                        },
                        body: {
                          emptyDataSourceMessage: "Нет данных",
                          filterRow: {
                            filterTooltip: "Фильтр"
                          },
                          editRow: {
                            deleteText: "Вы точно хотите удалить?",
                            cancelTooltip: "Отмена",
                            saveTooltip: "Сохранить"
                          },
                          addTooltip: "Добавить",
                          deleteTooltip: "Удалить",
                          editTooltip: "Редактировать"
                        }
                      }}
                      title="Таблица категорий"
                      columns={this.state.columns}
                      data={this.state.data}
                      onRowClick={(e, data) => {
                        this.props.history.push("/categories/edit/" + data.id);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CategoriesListContentComponent);
