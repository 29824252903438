import React from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import AddShopSliderContentComponent from './AddShopSliderContentComponent';

class AddShopSliderComponent extends React.Component {
    render() {
        return(
            <div className="main-panel">
                <AddShopSliderContentComponent location={this.props.location}/>
                <FooterComponent/>
            </div>
        );
    }
}

export default AddShopSliderComponent;