import React from "react";
import Select from "react-select";
import api from "../api";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { withRouter } from "react-router-dom";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

class AddCategoryContentComponent extends React.Component {
  state = {
    categories: [],
    selectedCategory: null,
    image: null,
    icon: null,
    name: "",
    submitted: false,
    error: false,
    loading: false,
    inMenu: true,
    inBanner: false,
  };

  componentDidMount() {
    api.categories.getAll().then(categories => this.setState({ categories }));
  }

  onNameChange = e => {
    this.setState({
      submitted: false,
      name: e.target.value
    });
  };

  onCategoryChange = selectedCategory => {
    this.setState({ selectedCategory, submitted: false });
  };

  onSubmit = e => {
    e.preventDefault();
    const error = this.validate();
    this.setState({ error, submitted: true, loading: true });
    if (error) this.setState({ loading: false });
    if (!error) {
      const formData = new FormData();
      const thumbnail = this.state.image;
      formData.append("thumbnail", thumbnail);
      formData.append("name", this.state.name);
      formData.append("in_menu", this.state.inMenu);
      formData.append("in_banner", this.state.inBanner);

      if (this.state.selectedCategory) {
        formData.append("parent_id", this.state.selectedCategory);
      }

      if (this.state.icon) {
        formData.append("icon", this.state.icon);
      }

      api.categories
        .store(formData)
        .then(res => this.props.history.push({pathname: "/categories/edit/" + res.id, state: {status: "created"}}))
        .catch(err => this.setState({ error: JSON.stringify(err) }))
        .then(() => this.setState({ submitted: false, loading: false }));
    }
  };

  validate = () => {
    let error = false;
    if (this.state.name == "")
      error = "Наименование категории - обязательное поле.";
    return error;
  };

  render() {
    return (
      <div className="content">
        {this.state.submitted && this.state.error && alert(this.state.error)}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card ">
                <div className="card-header card-header-rose card-header-text">
                  <div className="card-text">
                    <h4 className="card-title">Добавить категорию</h4>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Наименование категории
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <input
                          onChange={e => this.onNameChange(e)}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Картинка категории
                    </label>
                    <div className="col-sm-10">
                      <FilePond
                        ref={image => (this.pond = image)}
                        allowMultiple={false}
                        onupdatefiles={fileItems => {
                          this.setState({
                            submitted: false,
                            image: fileItems[0] ? fileItems[0].file : null
                          });
                        }}
                      ></FilePond>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Иконка категории
                    </label>
                    <div className="col-sm-10">
                      <FilePond
                        acceptedFileTypes={['image/png','image/jpeg','image/jpg','image/png']}
                        ref={image => (this.pond = image)}
                        allowMultiple={false}
                        onupdatefiles={fileItems => {
                          this.setState({
                            submitted: false,
                            icon: fileItems[0] ? fileItems[0].file : null
                          });
                        }}
                      ></FilePond>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Добавить в меню магазина
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <Switch
                          checked={this.state.inMenu}
                          onChange={() => this.setState({inMenu: !this.state.inMenu})}
              
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Добавить в блок категории на главном экране
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <Switch
                          checked={this.state.inBanner}
                          onChange={() => this.setState({inBanner: !this.state.inBanner})}
              
                          inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Родитель категории
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <Autocomplete
                          id="combo-box-demo"
                          multiple={false}
                          options={this.state.categories}
                          getOptionLabel={option => option.name}
                          onChange={(e, v) => {
                            this.setState({ selectedCategory: v.id });
                          }}
                          renderInput={params => (
                            <TextField {...params} label="Родитель" fullWidth />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-10">
                      <button
                        onClick={e => this.onSubmit(e)}
                        disabled={this.state.loading}
                        className="btn btn-success"
                      >
                        {this.state.loading
                          ? "Подождите..."
                          : "Добавить категорию"}
                        <div className="ripple-container"></div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddCategoryContentComponent);
