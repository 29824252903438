import React from "react";
import imgPath from "../utils/imgPath";
import { withRouter } from "react-router-dom";
import api from "../api";
import Swal from "sweetalert2";

class ShowCategoryContentComponent extends React.Component {
  onDeleteClick = id => {
    Swal.fire({
      title: "Вы уверены?",
      text: "После удаление - все категории товара будут удалены",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Удалить!"
    }).then(result => {
      if (result.value) {
        api.categories.delete(id).then(res => {
          Swal.fire("Удалено!", "Категория была удалена", "success");
          this.props.history.push("/categories/list");
        });
      }
    });
  };

  render() {
    const { loading, category } = this.props;
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              {loading ? (
                <p>Идет загрузка...</p>
              ) : (
                <div className="card">
                  <div className="card-header card-header-rose card-header-icon">
                    <div className="card-icon">
                      <i className="material-icons">assignment</i>
                    </div>
                    <h4 className="card-title">{category.name}</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-shopping">
                        <thead>
                          <tr>
                            <th className="text-center"></th>
                            <th>Наименование</th>
                            <th>Родитель категории</th>
                            <th className="text-right">Действия</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {category.thumbnail ? (
                                <div className="img-container">
                                  <img
                                    src={imgPath(category.thumbnail)}
                                    alt="..."
                                  />
                                </div>
                              ) : (
                                "Нет фото"
                              )}
                            </td>
                            <td className="td-name">{category.name}</td>
                            <td>{category.parent ? category.parent.name : "Нет родителя"}</td>
                            <td className="td-actions text-right">
                              <button
                                title="Редактировать"
                                onClick={() =>
                                  this.props.history.push(
                                    "/categories/edit/" + category.id
                                  )
                                }
                                type="button"
                                rel="tooltip"
                                className="btn btn-success btn-link"
                              >
                                <i className="material-icons">edit</i>
                              </button>
                              <button
                                title="Удалить"
                                onClick={() => this.onDeleteClick(category.id)}
                                type="button"
                                rel="tooltip"
                                className="btn btn-danger btn-link"
                              >
                                <i className="material-icons">close</i>
                              </button>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="3"></td>
                            <td colspan="2" className="text-right">
                              <button
                                type="button"
                                className="btn btn-info btn-round"
                              >
                                Посмотеть продукты{" "}
                                <i className="material-icons">
                                  keyboard_arrow_right
                                </i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ShowCategoryContentComponent);
