import React from "react";
import Select from "react-select";
import api from "../api";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { withRouter } from "react-router-dom";
import Tagify from "@yaireo/tagify";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { COLORS } from "../const";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateType);

class AddProductContentComponent extends React.Component {
  state = {
    categories: [],
    brands: [],
    purposes: [],
    colors: [],
    openDialog: true,
    selectedCategory: null,
    selectedSubCategory: null,
    selectedSubSubCategory: null,
    selectedBrand: null,
    selectedPurpose: null,
    images: null,
    name: "",
    description: "",
    price: "",
    count: 100,
    submitted: false,
    error: false,
    loading: false,
    tagify: null
  };

  componentDidMount() {
    const colorsInput = document.getElementById("colors-input");
    const tagify = new Tagify(colorsInput, { whitelist: [] });
    this.setState({ tagify });

    api.purposes.getAll().then(purposes => this.setState({ purposes }));
    api.brands.getAll().then(brands => this.setState({ brands }));
    api.categories
      .getAllWithChilds()
      .then(categories => this.setState({ categories }));
  }

  onNameChange = e => {
    this.setState({
      submitted: false,
      name: e.target.value
    });
  };

  onDescriptionChange = e => {
    this.setState({
      submitted: false,
      description: e.target.value
    });
  };

  onPriceChange = e => {
    this.setState({
      submitted: false,
      price: e.target.value
    });
  };

  onCountChange = e => {
    this.setState({
      submitted: false,
      count: e.target.value
    });
  };

  onCategoryChange = e => {
    const category = this.state.categories.filter(
      category => category.id == e.target.value
    )[0];
    this.setState({ selectedCategory: category, submitted: false });
  };

  onSubCategoryChange = e => {
    const category = this.state.selectedCategory.childs.filter(
      category => category.id == e.target.value
    );
    if (category.length > 0) {
      this.setState({ selectedSubCategory: category[0], submitted: false });
    }
  };

  onSubSubCategoryChange = e => {
    this.setState({ selectedSubSubCategory: e.target.value, submitted: false });
  };

  onBrandChange = e => {
    this.setState({ selectedBrand: e.target.value, submitted: false });
  };

  onPurposeChange = e => {
    this.setState({ selectedPurpose: e.target.value, submitted: false });
  };

  onSubmit = e => {
    e.preventDefault();
    const error = this.validate();
    this.setState({ error, submitted: true, loading: true });
    if (error) this.setState({ loading: false, openDialog: true });
    if (!error) {
      const formData = new FormData();
      const images = this.state.images;

      images.map(image => formData.append("images", image));
      formData.append("name", this.state.name);
      formData.append("description", this.state.description);
      formData.append("count", this.state.count);
      formData.append("price", this.state.price.replace(/\s/g, ""));
      formData.append("category_id", this.state.selectedSubSubCategory);
      formData.append("brand_id", this.state.selectedBrand);

      if (this.state.selectedPurpose) {
        formData.append("purpose_id", this.state.selectedPurpose);
      }


      if (this.state.colors.length > 0) {
        const colors = this.state.colors[this.state.colors.length - 1];
        console.log('colors', colors)
        if (colors.length > 0) {
          console.log('len', colors.length)
          const productColors = [];
          colors.map(color => {
            const productColor = {
              name: color
            };
            productColors.push(productColor);
          });
          console.log('colo', productColors)
          formData.append("colors", JSON.stringify(productColors));
        }
      }

      api.products
        .store(formData)
        .then(res => this.props.history.push("/products/list/"))
        .catch(err => {
          this.setState({
            error: "Произошла непредвиденная ошибка",
            openDialog: true
          });
          if (err.response && err.response.data && err.response.data.error) {
            this.setState({ error: err.response.data.error, openDialog: true });
          }
        })
        .finally(() => this.setState({ loading: false }));
    }
  };

  validate = () => {
    let error = false;
    if (this.state.name == "")
      error = "Наименование товара - обязательное поле.";
    if (this.state.price == "") error = "Цена товара - обязательное поле.";
    if (this.state.count == "") error = "Количество товара - обязательное поле.";
    if (this.state.description == "") error = "Описание товара - обязательное поле.";
    if (!this.state.images) error = "Прикрепите картинку для товара.";
    if (!this.state.selectedSubSubCategory)
      error = "Выберите подподкатегорию для товара.";
    if (!this.state.selectedBrand) error = "Выберите бренд для товара.";
    return error;
  };


  render() {
    return (
      <div className="content">
        {this.state.submitted && this.state.error && (
          <Dialog
            open={this.state.openDialog}
            onClose={() => this.setState({ openDialog: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Произашла ошибка"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.error}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ openDialog: false })}
                color="primary"
                autoFocus
              >
                Закрыть
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card ">
                <div className="card-header card-header-rose card-header-text">
                  <div className="card-text">
                    <h4 className="card-title">Добавить товар</h4>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Наименование товара
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <input
                          onChange={e => this.onNameChange(e)}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Описание товара
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <textarea
                          rows={8}
                          onChange={e => this.onDescriptionChange(e)}
                          type="text"
                          className="form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Цена товара
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <input
                          onChange={e => this.onPriceChange(e)}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Количество товара (запас)
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <input
                          onChange={e => this.onCountChange(e)}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Картинки товара
                    </label>
                    <div className="col-sm-10">
                      <FilePond
                        acceptedFileTypes={['image/png','image/jpeg','image/jpg','image/png']}
                        ref={image => (this.pond = image)}
                        allowMultiple={true}
                        onupdatefiles={fileItems => {
                          this.setState({
                            submitted: false,
                            images: fileItems.map(fileItem => fileItem.file)
                          });
                        }}
                      ></FilePond>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Категория товара
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <select
                          onChange={this.onCategoryChange}
                          className="form-control"
                        >
                          <option value={null}>Выберите категорию</option>
                          {this.state.categories.map(category => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {this.state.selectedCategory &&
                    this.state.selectedCategory.childs && (
                      <div className="row">
                        <label className="col-sm-2 col-form-label">
                          Подкатегория
                        </label>
                        <div className="col-sm-10">
                          <div className="form-group">
                            <select
                              onChange={this.onSubCategoryChange}
                              className="form-control"
                            >
                              <option value={null}>Выберите категорию</option>
                              {this.state.selectedCategory.childs.map(
                                category => (
                                  <option key={category.id} value={category.id}>
                                    {category.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                  {this.state.selectedSubCategory &&
                    this.state.selectedSubCategory.childs && (
                      <div className="row">
                        <label className="col-sm-2 col-form-label">
                          Еще подкатегория
                        </label>
                        <div className="col-sm-10">
                          <div className="form-group">
                            <select
                              onChange={this.onSubSubCategoryChange}
                              className="form-control"
                            >
                              <option value={null}>Выберите категорию</option>
                              {this.state.selectedSubCategory.childs.map(
                                category => (
                                  <option key={category.id} value={category.id}>
                                    {category.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Бренд товара
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <select
                          onChange={this.onBrandChange}
                          className="form-control"
                        >
                          <option value={null}>Выберите бренд</option>
                          {this.state.brands.map(brand => (
                            <option key={brand.id} value={brand.id}>
                              {brand.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">
                      Цель (для кого/чего)
                    </label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <select
                          onChange={this.onPurposeChange}
                          className="form-control"
                        >
                          <option value={null}>необязательно</option>
                          {this.state.purposes.map(purpose => (
                            <option key={purpose.id} value={purpose.id}>
                              {purpose.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="col-sm-2 col-form-label">Цветы</label>
                    <div className="col-sm-10">
                      <div className="form-group">
                        <Autocomplete
                          multiple
                          id="colors"
                          freeSolo
                          options={COLORS}
                          onChange={(event, value) => {
                            if (value.length > 0) {
                              this.setState({
                                colors: [...this.state.colors, value]
                              });
                            }
                          }}
                    
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Можно выбрать несколько вариантов"
                              placeholder="необязательно"
                              fullWidth
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-10">
                      <button
                        onClick={e => this.onSubmit(e)}
                        disabled={this.state.loading}
                        className="btn btn-success"
                      >
                        {this.state.loading ? "Подождите..." : "Добавить товар"}
                        <div className="ripple-container"></div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AddProductContentComponent);
