import React from 'react';
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import AddCategoryContentComponent from './AddCategoryContentComponent';

class AddCategoryComponent extends React.Component {
    render() {
        return (
            <div className="main-panel">
                <AddCategoryContentComponent />
                <FooterComponent />
            </div>
        );
    }
}

export default AddCategoryComponent;