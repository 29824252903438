import React from 'react';
import FooterComponent from './FooterComponent';
import EditHomePageSliderContentComponent from './EditHomePageSliderContentComponent';

class EditHomePageSliderComponent extends React.Component {
    render() {
        return (
            <div className="main-panel">
                <EditHomePageSliderContentComponent id={this.props.id}/>
                <FooterComponent />
            </div>
        );
    }
}

export default EditHomePageSliderComponent;