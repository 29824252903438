import React from "react";
import api from "../api";
import MaterialTable from "material-table";
import imgPath from "../utils/imgPath";
import { withRouter } from "react-router-dom";

class ShopPageSlidersListContentComponent extends React.Component {
  state = {
    loading: true,
    sliders: [],
    columns: [
      {
        title: "Фото",
        field: "image",
        render: rowData => (
          <img src={imgPath(rowData.path)} style={{ width: 50 }} />
        )
      },
      { title: "ID", field: "id" },
      { title: "1-текст", field: "top_sub_text" },
      { title: "Главный текст", field: "main_text" },
      { title: "2-текст", field: "bottom_sub_text" },
      { title: "Скидка в %", field: "discount_pct" },
      { title: "Номер товара", field: "product_id" },
      { title: "Позиция", field: "position" }
    ],
    data: []
  };

  componentDidMount() {
    api.shopPageSliders
      .getAll()
      .then(sliders => {
        if (sliders) {
          this.setState({ data: sliders });
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              {this.state.loading ? (
                <p>Идет загрузка...</p>
              ) : (
                <div className="card">
                  <div className="card-header card-header-rose card-header-icon">
                    <div className="card-icon">
                      <i className="material-icons">assignment</i>
                    </div>
                    <h4 className="card-title">Слайдеры</h4>
                  </div>
                  <div className="card-body">
                    <MaterialTable
                      editable={{
                        onRowDelete: oldData =>
                          api.shopPageSliders
                            .delete(oldData.id)
                            .then(res => {
                              this.setState(prevState => {
                                const data = [...prevState.data];
                                data.splice(data.indexOf(oldData), 1);
                                return { ...prevState, data };
                              });
                            })
                            .catch(err =>
                              this.setState({
                                openDialog: true,
                                error: "Неудалось удалить бренд"
                              })
                            )
                      }}
                      options={{
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 20]
                      }}
                      isLoading={this.state.loading}
                      localization={{
                        pagination: {
                          labelDisplayedRows: "{from}-{to} из {count}",
                          labelRowsSelect: "строк",
                          firstAriaLabel: "Первая страница",
                          firstTooltip: "Первая страница",
                          previousAriaLabel: "Пред. страница",
                          previousTooltip: "Пред. страница",
                          nextAriaLabel: "След. страница",
                          nextTooltip: "След. страница",
                          lastAriaLabel: "Последняя страница",
                          lastTooltip: "Последняя страница"
                        },
                        toolbar: {
                          nRowsSelected: "{0} строк выбрано",
                          searchPlaceholder: "Поиск",
                          searchTooltip: "Поиск"
                        },
                        header: {
                          actions: "Действия"
                        },
                        body: {
                          emptyDataSourceMessage: "Нет данных",
                          filterRow: {
                            filterTooltip: "Фильтр"
                          },
                          editRow: {
                            deleteText: "Вы точно хотите удалить?",
                            cancelTooltip: "Отмена",
                            saveTooltip: "Сохранить"
                          },
                          addTooltip: "Добавить",
                          deleteTooltip: "Удалить",
                          editTooltip: "Редактировать"
                        }
                      }}
                      title="Список слайдеров"
                      columns={this.state.columns}
                      data={this.state.data}
                      onRowClick={(e, data) => {
                        this.props.history.push("/shop-page-sliders/edit/" + data.id);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ShopPageSlidersListContentComponent);
